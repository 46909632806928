var handleSidebarWidgetForm = {
	init: function() {

		jQuery('.postcode-search select').change(function(){
            var value = jQuery(this).val();
            window.location.href = "/region/"+value;
        });
	}
};

export default handleSidebarWidgetForm;