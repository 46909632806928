var magnificPopup = {
    init: function() {
        jQuery(".single-locations .open-enquiry-popup").click(function(e) {
            e.stopPropagation();
            var $form = jQuery(".single-location__service-enquiry");

            var button =
                '<button title="Close (Esc)" type="button" class="close-modal">×</button>';

            jQuery(
                ".single-location__service-enquiry .single-location__service-enquiry-inner"
            ).prepend('<button class="close-modal">' + button + "</button>");

            $form.addClass("open");
        });
        jQuery(document).on("click", ".close-modal", function(e) {
            $form.removeClass("open");
            e.stopPropagation();
        });

        jQuery(".banner_slider__email").click(function(e) {
            e.stopPropagation();

            jQuery.magnificPopup.open({
                items: {
                    src: jQuery(this)
                        .parents(".banner_slider")
                        .find(".banner_slider__form")
                        .html(),
                    type: "inline"
                }
            });
        });

        jQuery('.video-gallery .open').magnificPopup({
            type: "iframe",
            mainClass: 'video-modal',
            callbacks: {
                markupParse: function(template, values, item) {
                    template.find('mfp-content').addClass('video-popup');
                }
            }
        });
    }
};

export default magnificPopup;