var postcodeValidator = {
    init: function() {
        var $this = this;

        jQuery("form.validate-postcode").each(function() {
            jQuery(this).submit(function(e) {
                var postCode = jQuery(this)
                    .find('input[name="postcode"]')
                    .val();
                if (!$this.validate(postCode)) {
                    e.preventDefault();
                    e.stopPropagation();
                    jQuery(this)
                        .find(".error")
                        .html("Please enter a valid Postcode (eg SL6 8BW)");
                }
            });
        });
    },
    validate: function(postcode) {
        // postcode = postcode.replace(/\s/g, "");
        // https://stackoverflow.com/questions/164979/uk-postcode-regex-comprehensive
        // var regex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i;
        var regex = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/i;

        return regex.test(postcode);
    }
};

export default postcodeValidator;
