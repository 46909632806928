var submitOnChange = {
    init: function(selector) {
        if(jQuery(selector).length) {
            this.addEventListener(selector);
        }
    },

    addEventListener:function(selector){
        jQuery(selector).on('change', function(){
            console.log("HELL");
            jQuery(selector).closest('form').submit();
        });
    }
}

export default submitOnChange;