var pageSlideShow = {
    init: function () {
        var svgPrevious =
            '<button class="slick-prev"><svg  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="5" height="14" viewBox="0 0 5 14"><path fill="#444444" d="M4.898 4.25q0 0.102-0.078 0.18l-3.070 3.070 3.070 3.070q0.078 0.078 0.078 0.18t-0.078 0.18l-0.391 0.391q-0.078 0.078-0.18 0.078t-0.18-0.078l-3.641-3.641q-0.078-0.078-0.078-0.18t0.078-0.18l3.641-3.641q0.078-0.078 0.18-0.078t0.18 0.078l0.391 0.391q0.078 0.078 0.078 0.18z"></path></svg></button>';
        var svgNext =
            '<button class="slick-next"><svg  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="5" height="14" viewBox="0 0 5 14"><path fill="#444444" d="M4.648 7.5q0 0.102-0.078 0.18l-3.641 3.641q-0.078 0.078-0.18 0.078t-0.18-0.078l-0.391-0.391q-0.078-0.078-0.078-0.18t0.078-0.18l3.070-3.070-3.070-3.070q-0.078-0.078-0.078-0.18t0.078-0.18l0.391-0.391q0.078-0.078 0.18-0.078t0.18 0.078l3.641 3.641q0.078 0.078 0.078 0.18z"></path></svg></button>';

        jQuery(".testimonial .page-slideshow__slick").slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
        });
        jQuery(".testimonial__employee .testimonial__items").slick({
            arrows: false,
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
        jQuery(".banner_slider .page-slideshow__slick").slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            arrows: true,
            prevArrow: svgPrevious,
            nextArrow: svgNext,
        });
        jQuery(".single-location__testimonial .page-slideshow__slick").slick({
            arrows: true,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: false,
            prevArrow: '<button class="slick-prev">&#9668;</button>',
            nextArrow: '<button class="slick-next">&#9658;</button>',
            dots: true,
        });

        jQuery("[data-slick]").slick();
    },
};

export default pageSlideShow;
