var geoLocation = {
    init: function () {
        var triggers = document.querySelectorAll(".js-geolocate-trigger");
        for (var i = 0; i < triggers.length; i++) {
            triggers[i].addEventListener(
                "click",
                this.getLatLon.bind(this, triggers[i].dataset.target),
                false
            );
        }
    },
    getLatLon: function (target) {
        if (navigator.geolocation) {
            var _self = this;
            navigator.geolocation.getCurrentPosition(function (position) {
                var coords = position.coords;
                _self.getPostcode(coords.latitude, coords.longitude, target);
            });
        }
    },
    getPostcode: function (lat, lon, target) {
        var request = new XMLHttpRequest();
        request.open(
            "GET",
            "https://api.postcodes.io/postcodes?lon=" +
                lon +
                "&lat=" +
                lat +
                "&radius=250",
            true
        );
        request.onload = function () {
            var data = JSON.parse(this.response);
            // console.log(data);
            catchjs.log("postcodes.io response: ", data);
            //Check for result and successful HTTP response
            if (data.result && request.status >= 200 && request.status < 400) {
                postcode = data.result[0].postcode;

                var $targets = jQuery(".js-geolocate-input");

                // return postcode;
                $targets.val(postcode);

                $targets.addClass("postcode__autopopulated");
            } else {
                $targets.val("Sorry, not found");
            }
            jQuery(target).focus();
        };

        request.send();
    },
};

export default geoLocation;