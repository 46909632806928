import accordion from "./_accordion";
import contactFormModal from "./_contactFormModal";
import geoLocation from "./_geoLocation";
import handleSidebarWidgetForm from "./_handlesidebarWidgetForm";
import magnificPopup from "./_maginificPopup";
import mobileNav from "./_mobile-nav";
import pageSlideShow from "./_pageSlideShow";
import postcodeValidator from "./_postcodeValidator";
import submitOnChange from "./_submitOnChange";
import tdBlogIndex from "./_tdBlogIndex";
import checkVoucherAvailability from "./checkVoucherAvailability";
import checkVoucherAvailabilityBasic from "./checkVoucherAvailabilityBasic";
/**
 *
 * To create new scripts create a partial inside src and right your code there either inside
 * a function or object oriented.
 *
 * Then call it on the relevant page below or in the common function to be run on every page.
 *
 */

(function ($) {
    var Roots = {
        // All pages
        common: {
            init: function () {
                mobileNav.init();
                pageSlideShow.init();
                accordion.init();
                handleSidebarWidgetForm.init();
                postcodeValidator.init();
                checkVoucherAvailability.init(postcodeValidator);
                checkVoucherAvailabilityBasic.init(postcodeValidator);
                magnificPopup.init();
                contactFormModal.init();
                geoLocation.init();
                submitOnChange.init(
                    '.job-filters .category-checkbox input[type="checkbox"]'
                );
                submitOnChange.init(".job-filters #location");
            },
        },
        // Home page
        home: {
            init: function () {
                // JavaScript to be fired on the home page
            },
        },
        // Blog index page
        blog: {
            init: function () {
                tdBlogIndex.init();
            },
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var namespace = Roots;
            funcname = funcname === undefined ? "init" : funcname;
            if (
                func !== "" &&
                namespace[func] &&
                typeof namespace[func][funcname] === "function"
            ) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            UTIL.fire("common");

            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function (i, classnm) {
                    UTIL.fire(classnm);
                }
            );
        },
    };

    jQuery(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
