var checkVoucherAvailabilityBasic = {
    init: function (postcodeValidator) {
        var $this = this;

        jQuery(
            '.js-gift-cerfiticate-availability-basic-form input[type="submit"]'
        ).click(function (e) {
            e.preventDefault();

            var postCode = jQuery(this)
                .parents("form")
                .find('input[name="postcode"]')
                .val();

            if (!postcodeValidator.validate(postCode)) {
                jQuery(this)
                    .parents("form")
                    .find(".error")
                    .html("Please enter a valid Postcode (eg SL6 8BW)");
                return;
            }

            $this.sendAjax({
                postcode: postCode,
            });
        });
    },
    sendAjax: function (data) {
        jQuery.ajax({
            type: "post",
            dataType: "json",
            url: ajax.ajaxurl,
            data: {
                action: "check_voucher_availability_basic",
                postcode: data.postcode,
            },
            success: function (response) {
                if( 'redirect' == response.data.html ){
                    window.location.replace("/product/molly-maid-gift-voucher/");
                }else{
                    jQuery(".gift-voucher-availability-wrapper").html(
                        response.data.html
                    );
                }
            },
        });
    },
};

export default checkVoucherAvailabilityBasic;
