var accordion = {

	init: function() {

		jQuery(".accordion__title").click(function(){
			jQuery(this).toggleClass('open');
		});

		jQuery(".accordion-block__single .title").click(function(){
			jQuery(this).parent().toggleClass('open');
			jQuery(this).parent().find('.content').slideToggle();
		})
		
	}

};

export default accordion;