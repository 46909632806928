var checkVoucherAvailability = {
    init: function (postcodeValidator) {
        var $this = this;

        jQuery(
            '.js-gift-cerfiticate-availability-form input[type="submit"]'
        ).click(function (e) {
            e.preventDefault();

            var postCode = jQuery(this)
                .parents("form")
                .find('input[name="postcode"]')
                .val();

            if (!postcodeValidator.validate(postCode)) {
                jQuery(this)
                    .parents("form")
                    .find(".error")
                    .html("Please enter a valid Postcode (eg SL6 8BW)");
                return;
            }

            $this.sendAjax({
                postcode: postCode,
            });
        });

        jQuery(".region-search select").change(function () {
            $this.sendAjax({
                location_id: jQuery(this).val(),
            });
        });
    },
    sendAjax: function (data) {
        jQuery.ajax({
            type: "post",
            dataType: "json",
            url: ajax.ajaxurl,
            data: {
                action: "check_voucher_availability",
                postcode: data.postcode,
                location_id: data.location_id,
            },
            success: function (response) {
                jQuery(".gift-voucher-availability-wrapper").html(
                    response.data.html
                );
            },
        });
    },
};

export default checkVoucherAvailability;
