var contactFormModal = {

    init: function () {

        jQuery('.single-locations .open-enquiry-popup').click(function(e) { 

            jQuery('.single-location__service-enquiry').addClass('open');
            
        });

        jQuery('.single-location__service-enquiry-close').click(function(e) { 

            jQuery('.single-location__service-enquiry').removeClass('open');
            
        });

        

    
    }

};

export default contactFormModal;