var mobileNav = {
	init: function() {

		jQuery('.nav-control').click(function() {
			jQuery('.banner__nav nav').toggleClass('nav-open');
			jQuery('.nav-control').toggleClass('x close');
		});

		jQuery(window).resize(function() {
			jQuery('.banner__nav nav').removeClass('nav-open');
			jQuery('.nav-control').removeClass('x open');
			jQuery('#menu-primary-navigation li').removeClass('submenu-open');
		});

		jQuery(document).on('click','nav.nav-open  li.menu-item-has-children > a',function(e) {
			e.preventDefault();
			e.stopPropagation();
			jQuery(this).parent('.menu-item-has-children').toggleClass('submenu-open');
		});

	}

};

export default mobileNav;